import React, { useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { Seo } from "../components/Seo"

const PageTemplate = pageContext => {
  const data = pageContext.pageContext
  // console.log(pageContext, " console.log(pageContext)")
  useEffect(() => {
    const allTheImages = document.querySelectorAll(".wp-block-image")
    allTheImages.forEach(li => {
      li.classList.remove("open")

      li.addEventListener("click", e => {
        e.stopPropagation()

        // Add open to figure
        if (!li.classList.contains("open")) {
          li.classList.add("open")
          if (li.offsetParent) {
            li.offsetParent.classList.add("blackOver")
          }
        } else {
          li.classList.remove("open")
          if (li.offsetParent) {
            li.offsetParent.classList.remove("blackOver")
          }
        }
      })

      document.addEventListener("keydown", function (event) {
        if (event.key === "Escape") {
          li.classList.remove("open")
          if (li.offsetParent) {
            li.offsetParent.classList.remove("blackOver")
          }
        }
      })
    })
  }, [])

  return (
    <Layout>
      <h1 className="container my-5">{data.title} </h1>
      <div
        className="container my-5 col-10 bg-dark p-4"
        dangerouslySetInnerHTML={{ __html: data.content }}
      />
      <Link
        to="/"
        className="d-flex  justify-content-center  mx-auto text-black"
      >
        Go back to the homepage
      </Link>
    </Layout>
  )
}
export const Head = pageContext => (
  <Seo
    title={pageContext.pageContext.title}
    description={pageContext.pageContext.description}
  />
)
export default PageTemplate
